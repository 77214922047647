import { PERSONALITY_TEST_LABELS } from 'src/app/resources/personality-test-answer-labels';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { JobApplication } from 'src/app/models/job-application.model';
import { QUIZ_TYPES } from 'src/app/resources/quiz-types';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Question, TypeOfSkillsQuestion } from 'src/app/models/quiz.model';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { PersonalityTestAnswers } from 'src/app/models/personality-test.model';
import { ComponentCanDeactivate } from 'src/app/guards/can-deactivate-component.guard';
import { readQueryParameters } from 'src/app/shared-functions';

@Component({
  selector: 'app-quiz-question',
  templateUrl: './quiz-question.component.html',
  styleUrls: ['./quiz-question.component.scss'],
})
export class QuizQuestionComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @HostBinding('class.route-card') card = true;
  @HostBinding('class.first-card') firstCard: boolean;
  @HostBinding('class.last-card') lastCard: boolean;

  activeQuestionIndex = 0;
  questions: Question[] = [];
  activeQuestion: Question;
  selectedAnswer: number = null;
  quizType: QUIZ_TYPES;
  personalityTestAnswers: number[] = [0, 1, 2, 3, 4];
  enableButtons = true;
  personalityTestLanguage: string;
  selectedAnswers: number[] = [];

  canDeactivate = true;

  readonly QUIZ_TYPES = QUIZ_TYPES;
  readonly PERSONALITY_TEST_LABELS = PERSONALITY_TEST_LABELS;
  readonly TYPE_OF_SKILLS_QUESTION = TypeOfSkillsQuestion;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private quizStore: QuizStoreService,
    private quizService: QuizService,
    private toastr: ToastrService,
    private configService: AppConfigService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.trackRouterEvents();
    this.setQuestion();
    this.updateCardFlags();
  }

  trackRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(({navigationTrigger, url}: NavigationStart) => {
        this.canDeactivate = navigationTrigger !== 'popstate';

        if (navigationTrigger === 'popstate') {
          if (this.quizType == QUIZ_TYPES.KNOCKOUT_QUESTIONS) {
            this.router.navigate(['/job-details'], {queryParamsHandling: 'merge'});
            return;
          }
        }
      });
  }

  setQuestion(): void {
    const quiz = this.quizStore.quiz;

    if (!quiz) {
      this.router.navigate(['quiz'], { queryParamsHandling: 'merge' });
      return;
    }

    this.quizType = quiz.type;

    if (this.quizType === QUIZ_TYPES.PERSONALITY_TEST) {
      this.personalityTestLanguage = this.configService.config.job.psykometrikaLanguage;
    }
    const followUpAnswerIndex = readQueryParameters('following');

    if (followUpAnswerIndex) {
      this.selectedAnswers.push(parseInt(followUpAnswerIndex));
    }

    this.questions = quiz.questions;
    this.activeQuestion = followUpAnswerIndex ? quiz.questions[quiz.activeQuestionIndex].answers[followUpAnswerIndex].followUpQuestion : quiz.questions[quiz.activeQuestionIndex];
    this.activeQuestionIndex = quiz.activeQuestionIndex;
  }

  updateCardFlags(): void {
    this.firstCard = this.activeQuestionIndex === 0;
    this.lastCard = this.activeQuestionIndex === this.questions.length - 1;
  }

  handleAnswerSubmitError(errorResponse: HttpErrorResponse): void {
    const errorKeys = Object.keys(errorResponse.error?.errors || {});
    let error = '';

    if (errorKeys.indexOf('answer') >= 0 || errorKeys.indexOf('answers') >= 0) {
      error = this.translateService.instant('QUIZ.TOAST_ERROR_QUESTION_ANSWERED');
    } else if (errorKeys.indexOf('application') >= 0) {
      error = this.translateService.instant('QUIZ.TOAST_ERROR_QUIZ_COMPLETED');
      this.router.navigate(['/jobs']);
    } else {
      error = this.translateService.instant('QUIZ.TOAST_ERROR_TRY_AGAIN');
    }

    this.toastr.error(error);
    this.selectedAnswer = null;
    this.selectedAnswers.pop();
    this.enableButtons = true;
  }

  selectAnswer(answerIndex: number): void {
    if (this.activeQuestion.questionType === TypeOfSkillsQuestion.multipleAnswersQuestion) {
      this.selectedAnswer = answerIndex;
      this.submitQuestionAnswers();
      return;
    }
    if (this.selectedAnswer === null) {
      this.selectedAnswer = answerIndex;
      this.enableButtons = false;

      this.submitQuestionAnswers();
    }
  }

  submitQuestionAnswers(): void {
    if (this.quizType === QUIZ_TYPES.KNOCKOUT_QUESTIONS) {
      this.activeQuestion.isInhouseQuestion
        ? this.checkIfInhouseQuestionAnswerIsCorrect()
        : this.checkIfKnockoutQuestionAnswerIsCorrect();
      return;
    }

    if (this.quizType === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      this.submitBusinessQuestionAnswer();
      return;
    }

    if (this.quizType === QUIZ_TYPES.PERSONALITY_TEST) {
      this.submitPersonalityTestAnswer();
    }
  }

  checkIfInhouseQuestionAnswerIsCorrect(): void {
    this.quizService
      .checkIfInhouseQuestionAnswerIsCorrect(this.selectedAnswer)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleAnswerSubmitError(errorResponse);
          return throwError(() => errorResponse);
        })
      )
      .subscribe((result: boolean) => {
        if (!result) {
          this.quizStore.quiz.passAllInhouseCandidates = false;
        }

        this.quizService.setQuestionAnswered(
          { answer: this.selectedAnswer, isInhouseQuestion: true },
          result
        );
      });
  }

  checkIfKnockoutQuestionAnswerIsCorrect(): void {
    this.quizService
      .checkIfKnockoutQuestionAnswerIsCorrect(this.selectedAnswer)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleAnswerSubmitError(errorResponse);
          return throwError(() => errorResponse);
        })
      )
      .subscribe((result: boolean) => {
        this.quizService.setQuestionAnswered({ answer: this.selectedAnswer }, result);
      });
  }

  submitBusinessQuestionAnswer(): void {
    const isMultipleQuestion = this.activeQuestion.questionType === TypeOfSkillsQuestion.multipleAnswersQuestion;
    if (isMultipleQuestion && this.selectedAnswers.includes(this.selectedAnswer)) {
      this.selectedAnswers = this.selectedAnswers.filter(index => index !== this.selectedAnswer)
    } else {
      this.selectedAnswers.push(this.selectedAnswer);
    }
    if (isMultipleQuestion) {
      return;
    }
    const hasFollowUpQuestion = !!this.activeQuestion.answers[this.selectedAnswer].followUpQuestion;
    if (this.activeQuestion.questionType === TypeOfSkillsQuestion.questionWithFollowUpQuestions && hasFollowUpQuestion) {
      this.quizService.setQuestionAnswered({ answer: this.selectedAnswer, hasFollowUpQuestion: true }, null);
      this.selectedAnswer = null;
      this.enableButtons = true;
    } else {
      this.onSubmitBusinessQuestionAnswer();
    }
  }

  onSubmitBusinessQuestionAnswer(): void {
    if (this.selectedAnswers.length === 0) {
      this.toastr.error(this.translateService.instant('BUSINESS_TEST.NO_ANSWERS_SELECTED'));
      return;
    }
    if (this.activeQuestion.questionType === TypeOfSkillsQuestion.multipleAnswersQuestion
        && this.selectedAnswers.length > this.activeQuestion.maxNumberOfAnswersCandidateCanChoose)
      {
        this.toastr.error(this.translateService.instant('BUSINESS_TEST.TOO_MANY_ANSWERS_SELECTED',
          { maxNumber: this.activeQuestion.maxNumberOfAnswersCandidateCanChoose })
          )
        return;
      }
    this.quizService
      .submitBusinessQuestionAnswer(this.selectedAnswers)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleAnswerSubmitError(errorResponse);
          return throwError(() => errorResponse);
        })
      )
      .subscribe((result: JobApplication) => {
        this.quizService.setQuestionAnswered({ answer: this.selectedAnswer }, result);
        this.selectedAnswers = [];
      });
  }

  submitPersonalityTestAnswer(): void {
    this.quizService
      .submitPersonalityTestAnswer(this.selectedAnswer, this.activeQuestion.id)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleAnswerSubmitError(errorResponse);
          return throwError(() => errorResponse);
        })
      )
      .subscribe((result: PersonalityTestAnswers) => {
        this.quizService.setQuestionAnswered(
          {
            answer: this.selectedAnswer,
            questionId: this.questions[this.activeQuestionIndex].id,
          },
          result
        );
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
