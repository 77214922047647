import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, forkJoin, throwError } from 'rxjs';
import { JobApplication } from 'src/app/models/job-application.model';
import { PersonalityTestAnswers, PersonalityTest, LastQuestion, PersonalityTestContent } from 'src/app/models/personality-test.model';
import { Question } from 'src/app/models/quiz.model';
import { QUIZ_TYPES } from 'src/app/resources/quiz-types';
import { AppConfigService } from 'src/app/services/app-config.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { QuizService } from 'src/app/services/quiz.service';
import { formatPersonalityTestQuestions, setQuizTypeQueryParam } from 'src/app/shared-functions';

@Component({
  selector: 'app-personality-test-start',
  templateUrl: './personality-test-start.component.html',
  styleUrls: ['./personality-test-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalityTestStartComponent implements OnInit {

  jobApplication: JobApplication = this.configService.config.jobApplication;
  activeQuestionIndex = 0;
  @HostBinding('class.route-card') card = true;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private quizService: QuizService,
    private quizStore: QuizStoreService,
    private configService: AppConfigService,
    private progressBarService: ProgressBarService,
    private toastr: ToastrService,
    private translateService: TranslateService

  ) { }

  ngOnInit(): void {
  }

  personalityTestStart(): void {
    const { candidate } = this.jobApplication;

    forkJoin([
      this.quizService.getPersonalityTestAnswers(),
      this.quizService.getPersonalityTest(),
      this.quizService.checkIfPersonalityTestResultsExist(candidate.guid),
    ]).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const error = this.translateService.instant(
          'QUIZ.TOAST_ERROR_PERSONALITY_TEST_ERROR'
        );
        this.toastr.error(error);
        return throwError(() => errorResponse);
      }),
    ).subscribe(
      ([answers, questions, resultsExist]: [PersonalityTestAnswers, PersonalityTest, boolean]) => {
        if (
          resultsExist &&
          (Object.keys(answers).length === 0 || !answers.lastQuestion)
        ) {
          this.activeQuestionIndex = 0;
          this.router.navigate(['/quiz/personality-test-start'], { queryParamsHandling: 'merge'});
        } else {
          this.displayPersonalityQuestion(answers, questions);
        }
      }
    );
  }

  displayPersonalityQuestion(answers: PersonalityTestAnswers, questions: PersonalityTest): void {
    const { lastQuestion } = answers;
    const { language, content } = questions;

    this.quizStore.quiz = {
      type: QUIZ_TYPES.PERSONALITY_TEST,
      questions: formatPersonalityTestQuestions(content),
    };

    this.configService.config.job.psykometrikaLanguage = language;

    if (Object.keys(answers).length === 0 || !lastQuestion) {
      this.activeQuestionIndex = 0;
    } else {
      this.activeQuestionIndex = this.findLastAnsweredPersonalityTestIndex(lastQuestion, content) + 1;
    }
    this.navigateToQuizQuestion();
  }


  findLastAnsweredPersonalityTestIndex({ questionGroup, key }: LastQuestion, content: PersonalityTestContent): number {
    const lastAnsweredQuestionId = content[questionGroup][key].id;
    const lastAnsweredQuestionUUID = questionGroup;

    return this.quizStore.quiz.questions
      .findIndex(({ id, uuid }: Question) => {
        return (
          id === lastAnsweredQuestionId &&
          uuid === lastAnsweredQuestionUUID
        );
      });
  }

  navigateToQuizQuestion(): void {
    this.quizStore.activeQuestionIndex = this.activeQuestionIndex;
    const quizTypeParam = setQuizTypeQueryParam(this.quizStore.quiz.type);
    this.updateProgressBar(this.activeQuestionIndex);
    this.loaderService.hide();
    this.router.navigate([`/quiz/question/${this.activeQuestionIndex}/${quizTypeParam}`], { queryParamsHandling: 'merge'});
  }

  updateProgressBar(activeQuestionIndex: number): void {
    const progress = (100 * (activeQuestionIndex + 1)) / this.quizStore.quiz.questions.length;
    this.progressBarService.updateProgress(progress);
  }
}
